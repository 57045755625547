import React, { Component, Suspense, lazy, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import Sidebar from './shared/Sidebar';





const Salles = lazy(() => import('./webpage/Webpage'));
const Sallescalendar = lazy(() => import('./webpagecalendar/Webpagecalendar'));
const Parametreszones = lazy(() => import('./parametreszones/Parametreszones'));
const Parametressalles = lazy(() => import('./parametressalles/Parametressalles'));
const Parametresgroupes = lazy(() => import('./parametresgroupes/Parametresgroupes'));
const Parametrescategories = lazy(() => import('./parametrescategories/Parametrescategories'));

const Site = lazy(() => import('./editeur/Site'));
const Administrateurs = lazy(() => import('./administrateurs/Administrateurs'));
const Login = lazy(() => import('./user-pages/Login'));

//*PAS UTILISER
const Menu = lazy(() => import('./menu/Menu'));
const User = lazy(() => import('./user/User'));
const Media = lazy(() => import('./media/Media'));
const Groupes = lazy(() => import('./groupes/Groupes'));
const Error404 = lazy(() => import('./error/error404'));
const Editeur = lazy(() => import('./editeur/Editeur'));





//*ROUTES DE L'APPLICATION
const AppRoutes = (props) => {


  const [isLogined, setIsLogined] = useState(null);
  const [load, setLoad] = useState(false);

  const location = useLocation();

  //*function : pour connaitre le path
  function isPathActive(path) {


    //return <h1>Path is: {location.pathname}</h1>

    return location.pathname.startsWith(path);
  }

  useEffect(() => {



    //*ON CHECK SI L'UTILISATEUR EST CONNECTÉ OU NON 
    let isLogined = false;
    if (localStorage.getItem("isLogined")) {
      isLogined = true;
    }

    setIsLogined(isLogined);
    setLoad(true);


  }, []);


  if (load) {


    //*SI L'UTILISATEUR N'EST PAS CONNECTÉ
    if (!isLogined) {


      //*ON CHECK LA ROUTE QUI EST DEMANDÉE
      //*SI C'EST UNE SIGNATURE , UN DOCUMENT , CE SONT DES PAGES PUBLICS
      //*SI LA ROUTE COMMENCE PAR "/ADMIN", on demande une connexion 
      return (

        <Suspense fallback={<Spinner />}>
          <Switch>



            <Route exact path="/site/*" component={Site} />
            <Route path="/login" component={Login} />

            {/**QUAND RIEN NE MENE NULLE PART , ON REDIRIGE EN 404  
                   <Route exact path="/*" component={Error404 } />  
                   */}

          </Switch>

        </Suspense>
      )

    } else { //*SI L'UTILISATEUR EST CONNECTE => SI C'EST UN ADMINISTRATEUR






      //*RENDER
      return (


        <Suspense fallback={<Spinner />}>

          <Switch>




            <div className="main-panel" style={{
              marginLeft: 260,
            }}>


              {/**REDIRECTION DE LOGIN VERS LE SITE , la redirection se fais dans le component*/}
              <Route path="/login" component={Login} />


              {/***ROUTES ACESSIBLE A CEUX QUI SONT LOGGES */}

              {/**ON METS LA SIDEBAR UNIQUEMENT POUR LA PARTIE ADMIN*/}
              {isPathActive("/admin") &&
                <Sidebar />
              }

              {/**ON REDIRIGE PAR DEFAUT SUR LE SITE   */}
              <Route exact path="/" >
                <Redirect to="/admin" />
              </Route>
              <Route exact path="/admin" component={Salles} />


              {/*****
             
             <Route exact path="/admin/menu" component={ Menu } />
             <Route exact path="/admin/user" component={ User } />
             ***/}


              <Route exact path="/admin/media" component={Media} />

              {/***ROUTES RESERVES UNIQUEMENT POUR LES ADMINISTRATEURS*/}
              {(localStorage.getItem("statutAdmin") === "true") ?
                <>


                  <Route exact path="/admin/salles" component={Salles} />
                  <Route exact path="/admin/parametreszones" component={Parametreszones} />
                  <Route exact path="/admin/parametressalles/*" component={Parametressalles} />
                  <Route exact path="/admin/parametresgroupes" component={Parametresgroupes} />
                  <Route exact path="/admin/parametrescategories" component={Parametrescategories} />
                  <Route exact path="/admin/administrateurs" component={Administrateurs} />

                  <Route exact path="/admin/planning/*" component={Sallescalendar} />


                  <Route exact path="/admin/groupes" component={Groupes} />


                </>

                :


                <>


                  {(localStorage.getItem("statutModerateur") === "true") &&
                    <>


                      <Route exact path="/admin/salles" component={Salles} />
                      <Route exact path="/admin/parametresgroupes" component={Parametresgroupes} />
                      <Route exact path="/admin/planning/*" component={Sallescalendar} />
                      <Route exact path="/admin/groupes" component={Groupes} />
                    </>
                  }

                </>
              }












              <div style={{ marginLeft: -260, }}>
                <Route exact path="/site/*" component={Site} />
              </div>

              {/**QUAND RIEN NE MENE NULLE PART , ON REDIRIGE EN 404  
            <Route exact path="/*" component={Error404 } />  
            */}

            </div>


            {/**ON REDIRIGE AU DEPART SUR LE SITE****/}
            <Redirect to="/admin/contrats" />
          </Switch>
        </Suspense>

      );
    }
  } else {
    return (
      <div>

      </div>
    )
  }

}

export default AppRoutes;